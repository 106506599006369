import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import CustomSelect from './components/CustomSelect/CustomSelect';
import DeleteButton from './components/DeleteButton/DeleteButton';
import './App.css';

function App() {
  const [files, setFiles] = useState([]);
  const [outputFormat, setOutputFormat] = useState('jpg');
  const [isInWordPress, setIsInWordPress] = useState(false);


  const onDrop = useCallback((acceptedFiles) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  const addImagesFromMediaLibrary = async (selectedImages) => {
    const newFilesPromises = selectedImages.map(async (image) => {
      const response = await fetch(image.url);
      const blob = await response.blob();
      
      // Extract file name with extension from the URL
      const fileName = image.url.split('/').pop();
  
      return new File([blob], fileName, { type: image.mime });
    });
  
    const newFiles = await Promise.all(newFilesPromises);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };
  
  

  const convertAndDownload = async () => {
    const mimeType = outputFormat === 'jpg' ? 'image/jpeg' : 'image/png';
    const extension = outputFormat === 'jpg' ? '.jpg' : '.png';
  
    const isInWordPress = window.isInWordPress || false;
  
    const uniqueId = () => {
      return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    };
  
    const zip = new JSZip();
  
    for (const file of files) {
      const dataUrl = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.readAsDataURL(file);
      });
  
      const image = await new Promise((resolve) => {
        const img = new Image();
        img.src = dataUrl;
        img.onload = () => resolve(img);
      });
  
      const canvas = document.createElement('canvas');
      canvas.width = image.width;
      canvas.height = image.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(image, 0, 0);
  
      const blob = await new Promise((resolve) =>
        canvas.toBlob((blob) => resolve(blob), mimeType, 0.95)
      );
  
      const fileName = file.name.replace(/\.[^.]+$/, extension);
  
      // Check if in WordPress and upload to media library without zipping
      if (typeof isInWordPress !== 'undefined' && isInWordPress) {
        const convertedFile = new File([blob], fileName, { binary: true });
        await uploadToMediaLibrary(convertedFile);
      } else {
        zip.file(fileName, blob, { binary: true });
      }
    }
  
    // If not in WordPress and zipping, download zip
    if (typeof isInWordPress === 'undefined' || !isInWordPress) {
      const content = await zip.generateAsync({ type: 'blob' });
      saveAs(content, 'converted-images.zip');
    }
  };

  async function uploadToMediaLibrary(file) {
    // If not in WordPress, do not upload
    const isInWordPress = window.isInWordPress || false;
    if (!isInWordPress) {
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
  
    // Get the API base URL from the global variable
    const apiBaseUrl = window.apiBaseUrl || '';
  
    const response = await fetch(`${apiBaseUrl}sws/v1/upload-image`, {
      method: 'POST',
      body: formData,
      credentials: 'same-origin', // Include cookies for authentication
    });
  
    const data = await response.json();
    if (data.success) {
      console.log('Image uploaded successfully:', data.attachment_id);
    } else {
      console.error('Image upload failed:', data.error);
    }
  }
  

  // Function to handle file deletion
  const deleteFile = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  // Add the addImagesFromMediaLibrary function to the window object
  useEffect(() => {
    if (typeof window.isInWordPress !== 'undefined') {
      setIsInWordPress(window.isInWordPress);
    }

    // Add the addImagesFromMediaLibrary function to the window object
    window.addImagesFromMediaLibrary = addImagesFromMediaLibrary;

    // Clean up the function on unmount
    return () => {
      window.addImagesFromMediaLibrary = undefined;
    };
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Image Converter</h1>
      </header>
      {isInWordPress && (
        <button
          className="select-from-media-library"
          onClick={() => {
            if (typeof window.isInWordPress !== 'undefined' && window.isInWordPress) {
              if (window.openCustomUploader) {
                window.openCustomUploader();
                return;
              }
            }
          }}
        >
          Select from Media Library
        </button>
      )}
      {isInWordPress && (
        <h3>Or upload an image to convert and upload below.</h3>
      )}
      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Drag and drop images here, or click to select files</p>
      </div>
      <div>
        <h3>Select output format:</h3>
        <CustomSelect
          value={outputFormat}
          onChange={(e) => setOutputFormat(e.target.value)}
          options={[
            { value: 'jpg', label: 'JPG' },
            { value: 'png', label: 'PNG' },
          ]}
        />
      </div>
      <button onClick={convertAndDownload} disabled={files.length === 0}>
        {isInWordPress ? 'Convert and Upload to Media Library' : 'Convert and Download as Zip'}
      </button>
      <div className="file-list">
        <h2>Files to be converted:</h2>
        <ul>
          {files.map((file, index) => (
            <li key={index}>
              <DeleteButton onClick={() => deleteFile(file)} />
              {file.name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default App;
